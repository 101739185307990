<template>
  <template v-if="deviceType === 'desktop'">
    <div class="row fm-background-main-color-desktop">
      <div class="col-1"></div>
      <div class="col-10">
        <NavbarNotLoggedIn></NavbarNotLoggedIn>
      </div>
      <div class="col-1"></div>
    </div>

    <div class="row fm-background-main-color-desktop">
      <div class="col-4"></div>
      <div class="col-4">

        <div class="container vh-100 fm-background-main-color-desktop">
          <div class="row">
            <div class="col-12 fm-main-label fm-label-main-color text-start fm-main-label-padding">
              Bez paniki!
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-start">
              Spokojnie, każdemu czasem zdarza się zapomnieć hasła.
              Wpisz poniżej adres email podany przy rejestracji, a następnie sprawdź skrzynkę mailową.
              Znajdziesz tam instrukcję jak zmienić hasło. Tylko tym razem zapisz je sobie w bezpiecznym miejscu.
            </div>
          </div>

          <template v-if="isResetLinkSent">
            Jeśli podany email był poprawny, to został na niego wysłany link do resetu hasła.
          </template>
          <template v-else>
            <div class="row fm-email-input-margin">
              <div class="col-md-12 fm-z-index-10">
                <form @submit.prevent="sendResetPasswordLink()">
                  <div class="mb-3 text-start">
                    <label for="email" class="form-label ">Tutaj wpisz adres email</label>
                    <input v-model="email" type="text" class="form-control" id="email" placeholder="Email" required>
                  </div>
                  <button type="submit" class="btn btn-primary w-100 fm-login-button fm-button-main-background-color fm-form-button-margin">
                    Wyślij instrukcję
                  </button>
                </form>
              </div>
            </div>
          </template>

          <div class="row">
            <div class="col-12">
              <div class="fixed-bottom">
                <img v-bind:src="'images/padlock-with-key.svg'" style="position: fixed; bottom: 4rem; right:0;">
              </div>
            </div>
          </div>

        </div>

      </div>
      <div class="col-4"></div>
    </div>
  </template>
  <template v-if="deviceType === 'mobile'">
    <div class="container vh-100 fm-background-main-color">
      <div class="row">
        <div class="col-12 fm-main-label fm-label-main-color text-start fm-main-label-padding">
          Bez paniki!
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-start">
          Spokojnie, każdemu czasem zdarza się zapomnieć hasła.
          Wpisz poniżej adres email podany przy rejestracji, a następnie sprawdź skrzynkę mailową.
          Znajdziesz tam instrukcję jak zmienić hasło. Tylko tym razem zapisz je sobie w bezpiecznym miejscu.
        </div>
      </div>

      <template v-if="isResetLinkSent">
        Jeśli podany email był poprawny, to został na niego wysłany link do resetu hasła.
      </template>
      <template v-else>
        <div class="row fm-email-input-margin">
          <div class="col-md-3"></div>
          <div class="col-md-6 fm-z-index-10">
            <form @submit.prevent="sendResetPasswordLink()">
              <div class="mb-3 text-start">
                <label for="email" class="form-label ">Tutaj wpisz adres email</label>
                <input v-model="email" type="text" class="form-control" id="email" placeholder="Email" required>
              </div>
              <button type="submit" class="btn btn-primary w-100 fm-login-button fm-button-main-background-color fm-form-button-margin">
                Wyślij instrukcję
              </button>
            </form>
          </div>
          <div class="col-md-3"></div>
        </div>
      </template>

      <div class="row">
        <div class="col-12">
          <div class="fixed-bottom">
            <img v-bind:src="'images/padlock-with-key.svg'" style="position: fixed; bottom: 4rem; right:0;">
          </div>
        </div>
      </div>

    </div>
  </template>
</template>

<script>
import LoginService from '../services/loginService';
import NavbarNotLoggedIn from '../components/NavbarNotLoggedIn';

export default {
  name: 'ForgotPassword',
  components: { NavbarNotLoggedIn },
  data() {
    return {
      email: null,
      isResetLinkSent: false,
      deviceType: 'desktop'
    };
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.setDeviceType();
  },
  methods: {
    onResize() {
      this.setDeviceType();
    },
    setDeviceType() {
      if (window.outerWidth > 576) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'mobile';
      }
    },
    async sendResetPasswordLink() {
      const response = await LoginService.sendResetPasswordLink(this.email);
      const result = response.data.isSuccess;

      if (result === true) {
        this.isResetLinkSent = true;
      }
    }
  }
};
</script>

<style scoped>
.fm-main-label-padding {
  padding-top: 1.5rem;
}

.fm-email-input-margin {
  margin-top: 1.5rem;
}

.fm-form-button-margin {
  margin-top: 2rem;
}

.fm-background-main-color-desktop {
  background-color: var(--blanched-almond);
}
</style>
